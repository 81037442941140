import { Link } from "gatsby";
import { GatsbyImage } from "gatsby-plugin-image";
import React from "react";
import Button from "react-bootstrap/Button";
import Card from "react-bootstrap/Card";
import Col from "react-bootstrap/Col";

const formattedDate = (date) => {
	const dateInfo = new Date(date);
	return dateInfo.toLocaleDateString("en-US", {
		weekday: "short",
		year: "numeric",
		month: "short",
		day: "2-digit",
	});
};

const EventCard = ({
	title,
	image,
	date,
	location,
	content,
	ctaButton,
	eventComingSoonLabel,
}) => {
	return (
		<Col lg={8} className="mb-4">
			<Card className="w-100 mb-5 rounded-5 event-card">
				<Card.Body className="p-0 d-flex flex-column flex-md-row">
					<GatsbyImage
						image={image?.localFile.childImageSharp.gatsbyImageData}
						alt={image?.altText}
						style={{ width: "192px" }}
					/>
					<div className="p-4">
						<div className="d-flex align-items-start align-items-lg-center flex-column flex-md-row">
							<Card.Title className="d-flex fs-5 mb-0 me-3 text-light-black">
								{title}{" "}
							</Card.Title>
							{eventComingSoonLabel && (
								<span className="my-3 my-md-0 bg-lighter-grey py-1 px-2 rounded text-light-black montserrat-medium fw-semibold">
									{eventComingSoonLabel}
								</span>
							)}
						</div>

						<div style={{ fontSize: "14px" }}>
							{date && (
								<span className="montserrat-light me-3 text-light-black">
									{formattedDate(date)}
								</span>
							)}
							<span className="montserrat-light text-light-black">
								Location: {location}
							</span>
						</div>
						<Card.Text>
							<div className="d-flex justify-content-between align-items-center pt-3">
								<div
									dangerouslySetInnerHTML={{ __html: content }}
									className="me-3 event-card-content"
									style={{ flex: 1 }}
								></div>
								{ctaButton && (
									<Button
										variant="secondary"
										size="sMedium"
										className="text-sm montserrat-bold fs-6 p-3 text-light-black"
										as={Link}
										to={`/posts${ctaButton.target}`}
									>
										{ctaButton.title}
									</Button>
								)}
							</div>
						</Card.Text>
					</div>
				</Card.Body>
			</Card>
		</Col>
	);
};

export default EventCard;
