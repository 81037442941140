import { graphql, useStaticQuery } from "gatsby";
import { GatsbySeo } from "gatsby-plugin-next-seo";
import React, { useEffect, useState } from "react";
import { Container } from "react-bootstrap";
import Row from "react-bootstrap/Row";
import { Helmet } from "react-helmet";
import EventCard from "../components/event-card";
import HeroBanner from "../components/hero";
import Layout from "../components/layout";

const EventsPage = () => {
	const data = useStaticQuery(graphql`
		query {
			site {
				siteMetadata {
					siteUrl
				}
			}
			pageData: wpPage(slug: { eq: "events" }) {
				seoFields {
					localBusinessSchema
					metaDescription
					opengraphDescription
					opengraphTitle
					title
					image {
						node {
							altText
							sourceUrl
							mediaDetails {
								height
								width
							}
						}
					}
				}
				eventsPageContent {
					eventsBannerSection {
						eventsBannerSectionHeading
						eventsBannerSectionDescription
						eventsBannerSectionBackgroundImage {
							node {
								altText
								sourceUrl
							}
						}
					}
				}
			}
			allWpEvent {
				nodes {
					title
					slug
					eventsPostFields {
						eventDescription
						eventComingSoonLabel
						eventLocation
						eventDate
						eventButton {
							title
							target
							url
						}
						eventImage {
							node {
								altText
								sourceUrl
								localFile {
									childImageSharp {
										gatsbyImageData(
											formats: WEBP
											quality: 80
											transformOptions: { cropFocus: CENTER, fit: COVER }
											layout: CONSTRAINED
											placeholder: BLURRED
										)
									}
								}
							}
						}
					}
					eventTypes {
						nodes {
							slug
							name
						}
					}
				}
			}
			allWpEventType {
				nodes {
					slug
					name
				}
			}
		}
	`);

	const siteUrl = data.site.siteMetadata.siteUrl;
	const {
		pageData: { seoFields, eventsPageContent },
		allWpEventType,
		allWpEvent,
	} = data;

	const [allEvents, setAllEvents] = useState([]);
	const [selectedFilter, setSelectedFilter] = useState("viewAll");

	useEffect(() => {
		setAllEvents(allWpEvent.nodes);
	}, [allWpEvent]);

	const handleCatClick = (name) => {
		setSelectedFilter(name);
	};

	useEffect(() => {
		if (selectedFilter === "viewAll") {
			setAllEvents(allWpEvent.nodes);
		} else {
			const allEventslist = [];

			allWpEvent.nodes.map((item) => {
				if (item.eventTypes.nodes.some((e) => e.slug === selectedFilter)) {
					allEventslist.push(item);
				}
			});

			setAllEvents(allEventslist);
		}
	}, [selectedFilter]);

	const breadcrumb = {
		"@context": "http://schema.org",
		"@type": "BreadcrumbList",
		itemListElement: [
			{
				"@type": "ListItem",
				position: "1",
				name: "Home",
				item: {
					url: `${siteUrl}`,
					id: `${siteUrl}`,
				},
			},
			{
				"@type": "ListItem",
				position: "2",
				name: "Events",
				item: {
					url: `${siteUrl}/events`,
					id: `${siteUrl}/events`,
				},
			},
		],
	};

	return (
		<>
			<Helmet>
				<script type="application/ld+json">{JSON.stringify(breadcrumb)}</script>
			</Helmet>
			<GatsbySeo
				title={seoFields?.title}
				description={seoFields?.metaDescription}
				language="en"
				openGraph={{
					type: "website",
					url: `${siteUrl}/events`,
					title: `${seoFields?.opengraphTitle}`,
					description: `${seoFields?.opengraphDescription}`,
					images: [
						{
							url: `${seoFields?.image?.node.sourceUrl}`,
							width: `${seoFields?.image?.node.mediaDetails.width}`,
							height: `${seoFields?.image?.node.mediaDetails.height}`,
							alt: `${seoFields?.image?.node.altText}`,
						},
					],
				}}
			/>

			<Layout>
				<HeroBanner
					heading={
						eventsPageContent?.eventsBannerSection.eventsBannerSectionHeading
					}
					content={
						eventsPageContent?.eventsBannerSection
							.eventsBannerSectionDescription
					}
					bgImage={
						eventsPageContent?.eventsBannerSection
							.eventsBannerSectionBackgroundImage?.node?.sourceUrl
					}
					showUnderline={false}
					textAlign={"center"}
					columns={7}
				/>
				<section className="py-5 py-md-8">
					<Container>
						<div className="d-flex justify-content-center">
							<ul
								className="bg-schema-black d-flex justify-content-center align-items-center rounded-5 gap-4 column-gap-5 gap-md-5 py-3 px-5 mb-0 flex-wrap"
								style={{ listStyle: "none" }}
							>
								<li
									className={`${
										selectedFilter === "viewAll"
											? "border-bottom border-2 border-black montserrat-bold"
											: ""
									} text-light-black`}
									style={{ cursor: "pointer" }}
									onClick={() => handleCatClick("viewAll")}
								>
									View all
								</li>
								{allWpEventType.nodes.map((item, i) => (
									<li
										onClick={() => handleCatClick(item.slug)}
										style={{ cursor: "pointer" }}
										className={`${
											selectedFilter === item.slug
												? "border-bottom border-2 border-black montserrat-bold"
												: ""
										}`}
									>
										{item.name}
									</li>
								))}
							</ul>
						</div>
						{allEvents.length > 0 ? (
							<Row className="justify-content-center mt-5">
								{allEvents.map((item, i) => (
									<EventCard
										key={i}
										title={item.title}
										image={item.eventsPostFields.eventImage.node}
										date={item.eventsPostFields.eventDate}
										location={item.eventsPostFields.eventLocation}
										content={item.eventsPostFields.eventDescription}
										ctaButton={item.eventsPostFields.eventButton}
										eventComingSoonLabel={
											item.eventsPostFields.eventComingSoonLabel
										}
									/>
								))}
							</Row>
						) : (
							<div className="mt-6 d-flex justify-content-center">
								NO Event Found
							</div>
						)}
					</Container>
				</section>
			</Layout>
		</>
	);
};

export default EventsPage;
